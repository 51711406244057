import React, {useEffect} from 'react'

// import Layout from '@components/Layout'
import Layout, {Section} from '../components/Layout'
import Image from '../components/Image'
import { t } from '../components/Translator'
// @ts-ignore
import content from '../../content/info.yaml'

/**
 * (!) Tab Bootstrap css, html, JavaScript (BSN) from:
 *      https://thednp.github.io/bootstrap.native/
 *      https://www.bestjquery.com/lab/tabs/
 *      https://bestjquery.com/tutorial/tab/demo82/
 *
 */
const InfoPage: React.FC = () => {

    const openingHours = content.opening_hours
    const liturgy = content.liturgy
    // const announcements = content.announcements

    // TODO 17/12/2022: COVID-19 tab hidden request Johan & Marc
    // const corona = content.corona

    useEffect(() => {

        const Tab = require('bootstrap.native/dist/components/tab-native')
        const infoTabs = document.getElementById('info-tabs')

        // todo: no idea what the qualifiedName 'A' stands for
        // @ts-ignore
        const infoTabsCollection = infoTabs.getElementsByTagName('A')
        Array.from(infoTabsCollection).map(
            tab => new Tab( tab, { height: true } )
        )

        return () => {
            // todo: research dispose of tabs? Without risk on memory leak?
            // if(!!carousel) carousel.dispose()
        }
    }, [])

    return (
        <Layout head={content.head} banner={content.banner}>
            <div className="row">
                <div className="tab">
                    <ul id="info-tabs" className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="opening-hours-tab" href="#opening-hours"
                               aria-controls="opening-hours" aria-selected="true" role="tab">{t(openingHours.title)}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="liturgy-tab" href="#liturgy" data-toggle="tab" data-height="true"
                               aria-controls="liturgy" aria-selected="false" role="tab">{t(liturgy.title)}</a>
                        </li>
                        {/*
                         TODO 14/6/2021: no announcements for now, opted to hide the tab
                        <li className="nav-item">
                            <a className="nav-link" id="announcements-tab" href="#announcements" data-toggle="tab" data-height="true"
                               aria-controls="announcements" aria-selected="false" role="tab">{t(announcements.title)}</a>
                        </li>
                         TODO 17/12/2022: COVID-19 tab hidden request Johan & Marc
                        <li className="nav-item">
                            <a className="nav-link" id="corona-tab" href="#corona" data-toggle="tab" data-height="true"
                               aria-controls="corona" aria-selected="false" role="tab">{t(corona.title)}</a>
                        </li>
                        */}
                    </ul>
                    <div className="tab-content tabs">
                        <div role="tabpanel" className="tab-pane fade active show" aria-labelledby="opening-hours-tab" id="opening-hours">
                            {/*
                            (!) Bootstrap mobile first: text first on mobile
                            result with the order-x Bootstrap classes
                            https://stackoverflow.com/questions/46672633/bootstrap-4-how-reverse-the-order-of-columns

                            NO result with the push/pull Bootstrap classes
                            https://codepen.io/ondrejsvestka/pen/qmbeMe
                            https://medium.com/@BootstrapC/bootstrap-push-pull-column-ordering-tutorial-59af58bd98de
                            */}
                            <div className="row">
                                {/*<div className="col-sm-4 col-sm-push-8">*/}
                                <div className="col-sm-7 order-sm-2">
                                    <Section content={openingHours.section}/>
                                    <div className="spacer-30"></div>
                                </div>
                                {/*<div className="col-sm-8 col-sm-pull-4">*/}
                                <div className="col-sm-5 order-sm-1">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <Image image={openingHours.images[0]}/>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-6">
                                            <Image image={openingHours.images[1]}/>
                                            <div className="spacer-30"></div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-6">
                                            <Image image={openingHours.images[2]}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" aria-labelledby="liturgy-tab" id="liturgy">
                            <div className="row">
                                <div className="col-sm-7 order-sm-2">
                                    <Section content={liturgy.section}/>
                                    <div className="spacer-30"></div>
                                </div>
                                <div className="col-sm-5 order-sm-1">
                                    <Image image={liturgy.images[0]}/>
                                </div>
                            </div>
                        </div>

                        {/*
                         TODO 14/6/2021: no announcements for now, opted to hide the tab
                        <div role="tabpanel" className="tab-pane fade" aria-labelledby="announcements-tab" id="announcements">
                            <div className="row">
                                <div className="col-sm-7 order-sm-2">
                                    <Section content={announcements.section}/>
                                    <div className="spacer-30"></div>
                                </div>
                                <div className="col-sm-5 order-sm-1">
                                    <Image image={announcements.images[0]}/>
                                </div>
                            </div>
                        </div>
                        TODO 17/12/2022: COVID-19 tab hidden request Johan & Marc
                        <div role="tabpanel" className="tab-pane fade" aria-labelledby="corona-tab" id="corona">
                            <div className="row">
                                <div className="col-sm-6 order-sm-2">
                                    <Section content={corona.section}/>
                                    <div className="spacer-30"></div>
                                </div>
                                <div className="col-sm-6 order-sm-1">
                                    <Image className={'responsive-image-bordered'} image={corona.images[0]}/>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default InfoPage